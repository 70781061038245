import Swiper from 'swiper'
import {
  Navigation,
  Pagination,
  Autoplay,
  Keyboard,
  Mousewheel,
  FreeMode,
} from 'swiper/modules'

document.addEventListener('turbolinks:load', function () {
  // ページ内で汎用的に使用するswiperの初期化
  new Swiper('.swiper-body', {
    modules: [Navigation, Pagination, Keyboard],
    loop: false,
    loopAdditionalSlides: 1, // loopAdditionalSlides: ループモード時に複製するスライド数を指定
    slidesPerView: 1,
    spaceBetween: 5,
    centeredSlides: true,
    grabCursor: true,
    keyboard: {
      enabled: true,
    },
    speed: 100,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  })

  // バナーに使用するswiperの初期化
  // swiper対象の画像枚数
  const bannerSlideLength = document.querySelectorAll(
    '.swiper-banner .swiper-slide',
  ).length
  // バナー画像枚数が1枚以下ならswiper初期化しない
  if (bannerSlideLength > 1) {
    new Swiper('.swiper-banner', {
      modules: [Autoplay, Keyboard],
      loop: true,
      loopAdditionalSlides: 1, // loopAdditionalSlides: ループモード時に複製するスライド数を指定
      slidesPerView: 'auto',
      spaceBetween: 0,
      threshold: 10,
      centeredSlides: true,
      grabCursor: true,
      keyboard: {
        enabled: true,
      },
      speed: 500,
      autoplay: {
        delay: 7000,
      },
    })
  }

  // adbanner_suppeに使用するswiperの初期化
  swiperInitializeForCard('.swiper-banner-suppe')

  // item_cardコンポーネントに使用するswiperの初期化
  swiperInitializeForCard('.swiper-item-card')

  // article_cardに使用するswiperの初期化
  swiperInitializeForCard('.swiper-article-card')

  // カード系を汎用的に初期化するメソッド
  function swiperInitializeForCard(target) {
    new Swiper(target, {
      modules: [Navigation, Keyboard, Mousewheel, FreeMode],
      slidesPerView: 'auto',
      spaceBetween: 3,
      threshold: 10,
      grabCursor: true,
      keyboard: {
        enabled: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      mousewheel: {
        enabled: true,
        forceToAxis: true,
      },
      freeMode: {
        enabled: true,
        sticky: true,
        momentumRatio: 0.3,
        momentumVelocityRatio: 0.35,
      },
    })
  }
})
